import Hero from "./components/Hero/Hero";
import LinkList from "./components/LinkList/LinkList";

//
//START: Uniform personalization code
import { 
  EsiChoose, EsiInclude, EsiOtherwise, 
  EsiWhen, EsiNullComponent, EsiForEach, 
  EsiAssign, EsiText, EsiScript, EsiNoOutput, 
  EsiContextCsr, EsiContextSsr 
} from '@uniformdev/esi-jss-react';
//
//END: Uniform personalization code

const components = new Map();
components.set("Hero", Hero);
components.set("LinkList", LinkList);


//
//START: Uniform personalization code
components.set('EsiChoose', EsiChoose); 
components.set('EsiInclude', EsiInclude);
components.set('EsiOtherwise', EsiOtherwise);
components.set('EsiWhen', EsiWhen);
components.set('EsiNullComponent', EsiNullComponent);
components.set('EsiForEach', EsiForEach);
components.set('EsiAssign', EsiAssign);
components.set('EsiText', EsiText);
components.set('EsiScript', EsiScript);
components.set('EsiNoOutput', EsiNoOutput);
components.set('EsiContextCsr', EsiContextCsr);
components.set('EsiContextSsr', EsiContextSsr);
//
//END: Uniform personalization code

export default function componentFactory(componentName) {
  return components.get(componentName);
}
